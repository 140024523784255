<template>
  <div>
    <el-result icon="info" title="Work in Progress" subTitle="Accrual temporarily removed for revamp. Please test in development site." v-if="!isAccrualEnabled()"/>

    <AdvanceTable ref="advanceTable" url="erp/accrual" :extraClass="profile.accountCode ? 'embbed' : ''" tableSize="sm" :columns="columns" :baseFilters="{organizationProfileId: profile.id, isShowDeleted: isShowDeleted}" :height="profile.accountCode ? window.height - 410 : window.height - 310" v-loading="isLoading" @edit="editAccrual" v-if="isAccrualEnabled()">
      <template #cell(accountCode)="row">
        {{ row.item.accountCode }}
        <div class="text-muted" v-if="row.item.organizationProfile && row.item.accountCode !== row.item.organizationProfile.accountCode">{{ row.item.organizationProfile.accountCode }}</div>
      </template>
      <template #cell(totalAmountReceivable)="row">
        {{ row.item.totalAmountReceivable.toFixed(2) }} {{ row.item.currency }}
        <div class="text-muted" v-if="row.item.originalCurrencyReceivable != row.item.currency">{{ row.item.originalTotalAmountReceivable.toFixed(2) }} {{ row.item.originalCurrencyReceivable }}</div>
      </template>
      <template #cell(totalAmountPayable)="row">
        {{ row.item.totalAmountPayable.toFixed(2) }} {{ row.item.currency }}
        <div class="text-muted" v-if="row.item.originalCurrencyPayable != row.item.currency">{{ row.item.originalTotalAmountPayable.toFixed(2) }} {{ row.item.originalCurrencyPayable }}</div>
      </template>
      <template #cell(actualAmountReceivable)="row">
        {{ row.item.actualAmountReceivable.toFixed(2) }} {{ row.item.currency }}
      </template>
      <template #cell(actualAmountPayable)="row">
        {{ row.item.actualAmountPayable.toFixed(2) }} {{ row.item.currency }}
      </template>
      <template #cell(varianceAmountReceivable)="row">
        {{ row.item.varianceAmountReceivable.toFixed(2) }} {{ row.item.currency }}
      </template>
      <template #cell(varianceAmountPayable)="row">
        {{ row.item.varianceAmountPayable.toFixed(2) }} {{ row.item.currency }}
      </template>
      <template #cell(addTime)="row">
        {{ formatWithCurrentTimezone(row.item.addTime) }}
      </template>
      <template #cell(status)="row">
        <AccrualStatusTag :item="row.item"/>
      </template>
      <template #cell(actions)="row">
        <span v-if="row.item.status !== 'DELETED'">
          <el-link @click.stop="editAccrual(row.item)" v-if="getErpCanWriteGlobal() && row.item.confirmFaTime === null">
            <i class="el-icon-edit"></i>
            Edit
          </el-link>

          <el-link @click.stop="confirmDeleteAccrual(row.item)" v-if="getErpCanWriteGlobal() && row.item.confirmFaTime === null">
            <i class="el-icon-delete"></i>
            Delete
          </el-link>

          <el-link @click.stop="editRemark(row.item)" v-if="getErpCanWriteGlobal()">
            <i class="el-icon-edit"></i>
            Remarks
          </el-link>

          <el-link @click.stop="confirmAccrual(row.item)" v-if="getErpCanWriteGlobal() && row.item.status === 'PENDING'">
            <i class="el-icon-check"></i>
            Confirm
          </el-link>

          <el-link @click.stop="confirmFaAccrual(row.item)" v-if="getErpCanWriteGlobal() && row.item.status === 'CONFIRMED'">
            <i class="el-icon-check"></i>
            F&amp;A Confirm
          </el-link>
        </span>

        <el-link @click.stop="showInvoices(row.item)" v-if="row.item.deleteTime === null">
          <i class="el-icon-document"></i>
          Invoices
        </el-link>

        <el-link @click.stop="showSummaries(row.item)" v-if="row.item.deleteTime === null">
          <i class="el-icon-document"></i>
          Summaries
        </el-link>

        <el-link @click="showAudits(row.item)">
          <i class="el-icon-search"></i>
          Audits
        </el-link>
      </template>

      <template #button>
        <el-switch
          v-model="isShowDeleted"
          active-text="Show Deleted"
          inactive-text=""
          class="mr-1" @change="loadAccruals()">
        </el-switch>

        <el-button type="info" size="small" @click="editSunCurrencyProfile()" v-if="getErpCanWriteGlobal() && !profile.id">
          <span class="font-weight-bold d-none d-md-block"><i class="el-icon-s-unfold"></i> SUN Currency Profile</span>
        </el-button>

        <el-button type="primary" size="small" @click="openModalForUpload" v-if="getErpCanWriteGlobal()">
          <i class="el-icon-upload"></i> Upload
        </el-button>

        <el-button type="primary" size="small" @click="createAccrual(profile.accountCode)" v-if="getErpCanWriteGlobal() && profile.id">
          <i class="el-icon-plus"></i> Create
        </el-button>

        <el-button-group class="mx-1">
          <el-button type="info" size="small" icon="el-icon-document" @click.exact="exportExcel('SUN_EXCEL')">
            Excel
          </el-button>

          <el-button type="info" size="small" icon="el-icon-sunny" @click.exact="exportExcel('SUN_TEXT')">
            Text
          </el-button>

          <el-button type="info" size="small" icon="el-icon-sort" @click.exact="exportExcel('VARIANCE_EXCEL')">
            Variance
          </el-button>

          <el-button type="info" size="small" icon="el-icon-document-copy" @click.exact="exportExcel('INVOICE_MAPPING_EXCEL')">
            Invoice
          </el-button>

          <el-button type="info" size="small" icon="el-icon-c-scale-to-original" @click.exact="exportExcel('SUMMARY_MAPPING_EXCEL')">
            Summary
          </el-button>
        </el-button-group>
      </template>
    </AdvanceTable>

    <el-dialog
      class="compact"
      :title="$t('Accrual/Invoice - ' + currentAccrual.accrualNumber)"
      :visible.sync="isShowInvoices"
      width="80%"
    >
      <b-row lg="12">
        <h4 style="margin: 6px 4px">Invoices</h4>
        <table class="table mb-2">
          <thead>
            <th>Mapped By</th>
            <th>Invoice Number</th>
            <th>Invoice Amount</th>
            <th>Reference Number</th>
            <th>Confirmed Time</th>
            <th v-if="action === 'SHOW'">Actions</th>
          </thead>
          <tbody>
            <tr v-for="invoice in accrualInvoices" :style="invoice.confirmTime ? {backgroundColor: '#f0f9eb'} : {backgroundColor: '#fcf6ec'}">
              <td>Invoice</td>
              <td>{{ invoice.invoiceNumber }}</td>
              <td>{{ invoice.totalAmount }} {{ invoice.totalAmountCurrency }}</td>
              <td>{{ invoice.customerReferenceNumber }}</td>
              <td>{{ formatWithCurrentTimezone(invoice.confirmTime) }}</td>
              <td v-if="action === 'SHOW'">
                <el-link @click="viewInvoice(invoice)"><i class="el-icon-view"></i> View</el-link>
                <el-link @click="editInvoice(invoice)" class="ml-1"><i class="el-icon-edit"></i> Edit</el-link>
                <el-link @click="removeInvoice(currentAccrual, invoice)" class="ml-1"><i class="el-icon-delete"></i> Remove</el-link>
              </td>
            </tr>
            <tr v-for="invoice in accrualInvoices2" :style="invoice.confirmTime ? {backgroundColor: '#f0f9eb'} : {backgroundColor: '#fcf6ec'}">
              <td>Item</td>
              <td>{{ invoice.invoiceNumber }}</td>
              <td>{{ invoice.totalAmount }} {{ invoice.totalAmountCurrency }}</td>
              <td>{{ invoice.customerReferenceNumber }}</td>
              <td>{{ formatWithCurrentTimezone(invoice.confirmTime) }}</td>
              <td v-if="action === 'SHOW'">
                <el-link @click="viewInvoice(invoice)"><i class="el-icon-view"></i> View</el-link>
                <el-link @click="editInvoice(invoice)" class="ml-1"><i class="el-icon-edit"></i> Edit</el-link>
                <!--<el-link @click="removeInvoice(currentAccrual, invoice)" class="ml-1"><i class="el-icon-delete"></i> Remove</el-link>-->
              </td>
            </tr>
          </tbody>
        </table>

        <hr/>

        <div v-if="action === 'SHOW' && currentAccrual.confirmTime === null">
          <el-select v-model="newInvoiceNumber">
            <el-option v-for="item in companyInvoices" :key="item.invoiceNumber" :label="item.invoiceNumber" :value="item.invoiceNumber" v-if="item.status !== 'DELETED'">
              {{ item.invoiceNumber }}
            </el-option>
          </el-select>

          <el-button type="primary" class="ml-1" @click="addInvoiceSubmit()"><i class="el-icon-plus"></i> Add New Invoice</el-button>
        </div>

        <div v-if="action === 'DELETE'">
          <el-button type="danger" class="ml-1" @click="doubleConfirmDeleteAccrual(currentAccrual, accrualInvoices.length !== 0 || accrualInvoices2.length !== 0)"><i class="el-icon-delete"></i> Delete Accrual</el-button>
        </div>

        <div v-if="action === 'CONFIRM'">
          <el-button type="primary" class="ml-1" @click="doubleConfirmConfirmAccrual(currentAccrual, accrualInvoices.filter(i => i.confirmTime === null).length !== 0 || accrualInvoices2.filter(i => i.confirmTime === null).length !== 0)"><i class="el-icon-check"></i> Confirm Accrual</el-button>
        </div>
      </b-row>
    </el-dialog>

    <el-dialog
      class="compact"
      :title="$t('Accrual/Summaries - ' + currentAccrual.accrualNumber)"
      :visible.sync="isShowSummaries"
      width="80%"
    >
      <b-row lg="12">
        <h4 style="margin: 6px 4px">Summaries</h4>
        <table class="table mb-2">
          <thead>
          <th>Summary Number</th>
          <th>Applied Amount</th>
          <th>Linked Time</th>
          <th>Confirmed Time</th>
          </thead>
          <tbody>
          <tr v-for="assoc in accrualSummaries" :style="assoc.incomingActualPayableSummary.confirmTime ? {backgroundColor: '#f0f9eb'} : {backgroundColor: '#fcf6ec'}">
            <td>{{ assoc.incomingActualPayableSummary.summaryNumber }}</td>
            <td>
              {{ assoc.appliedAmount }} {{ assoc.appliedAmountCurrency }}
              <div class="text-muted">{{ assoc.originalAppliedAmount }} {{ assoc.originalAppliedAmountCurrency }}</div>
            </td>
            <td>{{ formatWithCurrentTimezone(assoc.addTime) }}</td>
            <td>{{ formatWithCurrentTimezone(assoc.incomingActualPayableSummary.confirmTime) }}</td>
          </tr>
          </tbody>
        </table>
      </b-row>
    </el-dialog>

    <el-dialog
      class="compact"
      :title="$t('Remark - ' + currentAccrual.accrualNumber)"
      :visible.sync="isShowRemark"
    >
      <el-form :model="currentAccrual" :rules="currentAccrualRules" ref="mainForm">
        <el-form-item :label="$t('Remark')" prop="remark">
          <el-input type="textarea" v-model="currentAccrual.remark" :autosize="{ minRows: 5, maxRows: 10}"/>
        </el-form-item>

        <el-form-item :label="$t('F&A Remark')" prop="faRemark" class="mt-2">
          <el-input type="textarea" v-model="currentAccrual.faRemark" :autosize="{ minRows: 5, maxRows: 10}"/>
        </el-form-item>

        <div>
          <div class="w-100 d-flex justify-content-end">
            <b-button variant="primary" class="btn" @click="submitRemark()" >
              {{ $t('Submit') }}
            </b-button>
          </div>
        </div>
      </el-form>
    </el-dialog>

    <el-dialog
      class="compact"
      :title="$t(action + ' Accrual ' + currentAccrual.accrualNumber)"
      :visible.sync="isShowForm"
    >
      <el-form :model="currentAccrual" :rules="currentAccrualRules" ref="mainForm" class="compact">
        <b-row>
          <b-col lg="3">
            <el-form-item :label="$t('Account Code')" prop="accountCode">
              <el-input v-model="currentAccrual.accountCode" size="mini" disabled="disabled"/>
            </el-form-item>
          </b-col>
          <b-col lg="3">
            <el-form-item :label="$t('Billing Period (Month)')" prop="billingPeriodMonth">
              <el-date-picker type="month" value-format="yyyy-MM-dd" v-model="currentAccrual.billingPeriodMonth" size="mini" @change="onBillingPeriodMonthChange()" style="width: 100%"/>
            </el-form-item>
          </b-col>
          <b-col lg="3" v-if="false">
            <el-form-item :label="$t('Billing Period (From)')" prop="billingPeriodStart">
              <el-date-picker type="date" value-format="yyyy-MM-dd" v-model="currentAccrual.billingPeriodStart" size="mini" disabled="disabled" style="width: 100%"/>
            </el-form-item>
          </b-col>
          <b-col lg="3" v-if="false">
            <el-form-item :label="$t('Billing Period (To)')" prop="billingPeriodEnd">
              <el-date-picker type="date" value-format="yyyy-MM-dd" v-model="currentAccrual.billingPeriodEnd" size="mini" disabled="disabled" style="width: 100%"/>
            </el-form-item>
          </b-col>
          <b-col lg="3">
            <el-form-item :label="$t('Billing Period (Sun)')" prop="billingPeriod">
              <el-input v-model="currentAccrual.billingPeriod" size="mini" disabled="disabled"/>
            </el-form-item>
          </b-col>
          <b-col lg="3">
            <el-form-item :label="$t('Currency Profile')" prop="currencyProfileId">
              <el-select filterable size="mini" v-model="currentAccrual.currencyProfileId" style="width: 100%" @change="loadCurrencies()">
                <el-option v-for="item in currencyProfiles" :key="item.id" :label="item.name" :value="item.id">
                  {{ item.name }}
                </el-option>
              </el-select>
            </el-form-item>
          </b-col>
        </b-row>

        <b-row>
          <b-col lg="3">
            <el-form-item :label="$t('Customer Category')" prop="customerCategory">
              <el-input size="mini" v-model="currentAccrual.customerCategory"/>
            </el-form-item>
          </b-col>
          <b-col lg="3">
            <el-form-item :label="$t('Destination Country')" prop="destinationCountry">
              <el-input size="mini" v-model="currentAccrual.destinationCountry"/>
            </el-form-item>
          </b-col>
          <b-col lg="3">
            <el-form-item :label="$t('Warehouse Code')" prop="warehouseCode">
              <el-select filterable size="small" v-model="currentAccrual.warehouseCode" style="width: 100%">
                <el-option v-for="item in warehouseCodes" :key="item" :label="item" :value="item">
                  {{ item }}
                </el-option>
              </el-select>
            </el-form-item>
          </b-col>
          <b-col lg="3">
            <el-form-item :label="$t('Charge Code')" prop="chargeCode">
              <el-select size="small" filterable v-model="currentAccrual.chargeCode" class="w-100">
                <el-option v-for="item in chargeCodes" :key="item.code" :label="item.value" :value="item.code" v-if="item.status === 'ACTIVE'">
                  {{ item.category }} &gt; {{ item.level1 }} &gt; {{ item.level2 }} &gt; {{ item.code }}
                </el-option>
              </el-select>
            </el-form-item>
          </b-col>
        </b-row>

        <hr/>

        <b-row>
          <b-col lg="6">
            <b-row class="mt-half">
              <b-col lg="6">
                {{ $t('AR Currency') }}
              </b-col>
              <b-col lg="6">
                <el-select filterable size="mini" v-model="currentAccrual.originalCurrencyReceivable" style="width: 100%" @change="loadExchangeRateReceivable()">
                  <el-option v-for="item in currencies" :key="item" :label="item" :value="item">
                    {{ item }}
                  </el-option>
                </el-select>
              </b-col>
            </b-row>

            <b-row class="mt-half">
              <b-col lg="6">
                {{ $t('AR Exchange Rate') }}
              </b-col>
              <b-col lg="6">
                <el-input size="mini" v-model="currentAccrual.exchangeRateReceivable" @change="recalculateTotal()"/>
              </b-col>
            </b-row>

            <b-row v-for="charge in currentAccrual.charges" :key="charge.code" v-if="charge.code.startsWith('AR-')" class="mt-half">
              <b-col lg="6">
                {{ $t(charge.code) }}
              </b-col>
              <b-col lg="6">
                <el-input type="number" size="mini" v-model="charge.amount" @change="recalculateTotal()" step="0.01"/>
              </b-col>
            </b-row>

            <b-row class="mt-half">
              <b-col lg="6">
                <strong>{{ $t('AR-Total') }}</strong>
              </b-col>
              <b-col lg="6">
                <el-input type="number" size="mini" v-model="currentAccrual.originalTotalAmountReceivable" readonly/>
              </b-col>
            </b-row>

            <b-row class="mt-half">
              <b-col lg="6">
                <strong>{{ $t('Amount Receivable') }}</strong>
              </b-col>
              <b-col lg="4">
                <el-input size="mini" v-model="currentAccrual.totalAmountReceivable" readonly/>
              </b-col>
              <b-col lg="2">
                {{ currentAccrual.currency }}
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="6">
            <b-row class="mt-half">
              <b-col lg="6">
                {{ $t('AP Currency') }}
              </b-col>
              <b-col lg="6">
                <el-select filterable size="mini" v-model="currentAccrual.originalCurrencyPayable" style="width: 100%" @change="loadExchangeRatePayable()">
                  <el-option v-for="item in currencies" :key="item" :label="item" :value="item">
                    {{ item }}
                  </el-option>
                </el-select>
              </b-col>
            </b-row>

            <b-row class="mt-half">
              <b-col lg="6">
                {{ $t('AP Exchange Rate') }}
              </b-col>
              <b-col lg="6">
                <el-input size="mini" v-model="currentAccrual.exchangeRatePayable" @change="recalculateTotal()"/>
              </b-col>
            </b-row>

            <b-row v-for="charge in currentAccrual.charges" :key="charge.code" v-if="charge.code.startsWith('AP-')" class="mt-half">
              <b-col lg="6">
                {{ $t(charge.code) }}
              </b-col>
              <b-col lg="6">
                <el-input type="number" size="mini" v-model="charge.amount" @change="recalculateTotal()" step="0.01"/>
              </b-col>
            </b-row>

            <b-row class="mt-half">
              <b-col lg="6">
                <strong>{{ $t('AP-Total') }}</strong>
              </b-col>
              <b-col lg="6">
                <el-input type="number" size="mini" v-model="currentAccrual.originalTotalAmountPayable" readonly/>
              </b-col>
            </b-row>

            <b-row class="mt-half">
              <b-col lg="6">
                <strong>{{ $t('Amount Payable') }}</strong>
              </b-col>
              <b-col lg="4">
                <el-input size="mini" v-model="currentAccrual.totalAmountPayable" readonly/>
              </b-col>
              <b-col lg="2">
                {{ currentAccrual.currency }}
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row class="mt-1">
          <b-col lg="12">
            <el-form-item :label="$t('Remark')" prop="remark">
              <el-input size="mini" v-model="currentAccrual.remark"/>
            </el-form-item>
          </b-col>
        </b-row>

        <div>
          <div class="w-100 d-flex justify-content-end">
            <b-button variant="primary" class="btn" @click="submitCreateAccrual()" v-if="action === 'ADD'" :disabled="!isFormValid">
              {{ $t('Create') }}
            </b-button>
            <b-button variant="primary" class="btn" @click="submitEditAccrual()" v-if="action === 'EDIT'" :disabled="!isFormValid">
              {{ $t('Edit') }}
            </b-button>
          </div>
        </div>
      </el-form>
    </el-dialog>

    <el-dialog
      class="compact"
      :title="$t('SUN Currency Profile')"
      :visible.sync="isShowCurrencyProfile"
    >
      <el-alert class="mb-2" title="Currency Profile for SUN System Accrual calculation." type="success"></el-alert>

      <el-form>
        <b-row class="mt-2">
          <b-col lg="12">
            <el-form-item :label="$t('Currency Profile')" prop="currencyProfileId">
              <el-select filterable size="mini" v-model="currencyProfileId" style="width: 100%">
                <el-option v-for="item in currencyProfiles" :key="item.id" :label="item.name" :value="item.id">
                  {{ item.name }}
                </el-option>
              </el-select>
            </el-form-item>
          </b-col>
        </b-row>

        <div>
          <div class="w-100 d-flex justify-content-end">
            <b-button variant="primary" class="btn" @click="submitSunCurrencyProfile()">
              {{ $t('Save') }}
            </b-button>
          </div>
        </div>
      </el-form>
    </el-dialog>

    <UploadModal ref="uploadView">
      <template v-slot:tip="scope">
        Only accepts xlsx files with size less than {{ $refs.uploadView ? $refs.uploadView.getMaxSizeForDisplay() : '' }}<br/>
        <a href="https://prod-kec-dashboard.s3.ap-east-1.amazonaws.com/public/Accrual-Upload-Template.xlsx" target="_blank" @click.stop>Download Template</a>
      </template>
    </UploadModal>

    <ExcelResultModal ref="excelResult"/>
    <AuditTrailModal ref="auditTrail"/>
  </div>
</template>

<script>
import { BButton, BCard, BCardText, BCollapse, BModal, BTabs } from "bootstrap-vue";
import apis from "@/configs/apis";
import { EventBus } from "@/views/erp/utils/event-bus";
import { getAccrualIconStyle } from "@/views/erp/utils/accrual";
import AccrualStatusTag from "@/views/erp/components/AccrualStatusTag.vue";
import _ from "lodash";
import moment from "moment";
import { getErpCanWriteGlobal } from "@/utils/account-localstorage";
import AdvanceTable from "@/views/components/advanceTable/advanceTable.vue";
import { isAccrualEnabled, MAX_PAGE_SIZE } from "@/views/erp/utils/system";
import { formatWithCurrentTimezone } from "./utils/time";
import Prompt from "@/views/erp/mixins/Prompt";
import UploadModal from "@/views/erp/components/UploadModal.vue";
import ExcelResultModal from "@/views/erp/components/ExcelResultModal.vue";
import axios from "axios";
import AuditTrailModal from "@/views/erp/components/AuditTrailModal.vue";

export default {
  mixins: [Prompt],
  components: {
    AuditTrailModal,
    UploadModal,
    ExcelResultModal,
    AdvanceTable,
    AccrualStatusTag,
    BTabs,
    BCollapse,
    BButton,
    BModal,
    BCard,
    BCardText,
  },
  props: {
    profile: {
      type: Object,
      default() {
        return {}
      }
    },
    activeTab: String,
  },
  mounted() {
    if (!isAccrualEnabled()) return;

    this.loadAll();

    EventBus.$on('accrual-updated', () => {
      this.loadAccruals();
    });

    if (this.activeTab && this.activeTab.includes('accrual-')) {
      this.editAccrualById(this.activeTab.substring(9));
    }
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  data() {
    return {
      window: {
        width: 0,
        height: 0
      },
      action: 'ADD',
      isShowCopyAccrual: false,
      isShowForm: false,
      isShowRemark: false,
      isShowInvoices: false,
      isShowSummaries: false,
      isShowCurrencyProfile: false,
      currentAccrual: {
        billingPeriodMonth: null
      },
      currentAccrualRules: {
        accountCode: [{ required: true, message: 'Please input Account Code', trigger: 'blur' }],
        billingPeriodMonth: [{ required: true, message: 'Please input Billing Period Month', trigger: 'blur' }],
        billingPeriod: [{ required: true, message: 'Please input Billing Period', trigger: 'blur' }],
        originalCurrencyReceivable: [{ required: true, message: 'Please input Original Currency (AR)', trigger: 'blur' }],
        originalCurrencyPayable: [{ required: true, message: 'Please input Original Currency (AP)', trigger: 'blur' }],
        exchangeRate: [{ required: true, message: 'Please input exchange rate', trigger: 'blur' }],
      },
      accruals: [],
      chargeItems: [],
      currencies: [],
      currencyProfiles: [],
      chargeCodes: [],
      accrualInvoices: [],
      accrualInvoices2: [],
      accrualSummaries: [],
      companyInvoices: [],
      warehouseCodes: [],
      newInvoiceNumber: null,
      currencyProfileId: null,
      arKeys: ['Warehouse', 'Truck', 'Freight', 'Lastmile', 'Storage', 'Labour', 'Other'],
      isFormValid: true,
      isLoading: false,
      isShowDeleted: true,
      columns: [
        { key: "accrualNumber", modelName: "accrualNumber", label: "Accrual No.", width: "80", filtertype: "input", sortable: true },
        { key: "accountCode", modelName: "accountCode", label: "A/C Code", width: "80", filtertype: "input", sortable: true },
        { key: "customerCategory", modelName: "customerCategory", label: "Category", width: "100", filtertype: "input", sortable: true },
        { key: "warehouseCode", modelName: "warehouseCode", label: "WH Code", width: "80", filtertype: "input", sortable: true },
        { key: "billingPeriod", modelName: "billingPeriod", label: "Billing Period", width: "80", filtertype: "fiscal", sortable: true },
        { key: "totalAmountReceivable", modelName: "totalAmountReceivable", label: "TTL AMT RECV", width: "120", sortable: true },
        { key: "totalAmountPayable", modelName: "totalAmountPayable", label: "TTL AMT PAY", width: "120", sortable: true },
        { key: "actualAmountReceivable", modelName: "actualAmountReceivable", label: "ACT AMT RECV", width: "120",  sortable: true },
        { key: "actualAmountPayable", modelName: "actualAmountPayable", label: "ACT AMT PAY", width: "120",  sortable: true },
        { key: "varianceAmountReceivable", modelName: "varianceAmountReceivable", label: "VAR AMT RECV", width: "120", sortable: true },
        { key: "varianceAmountPayable", modelName: "varianceAmountPayable", label: "VAR AMT PAY", width: "120", sortable: true },
        { key: "status", modelName: "status", label: "Status", width: "80", filtertype: "select", options: { 'CONFIRMED': 'Confirmed', 'FA_CONFIRMED': 'F&A Confirmed', 'PENDING': 'Pending', 'DELETED': 'Deleted' }, sortable: true, align: "center" },
        { key: "addTime", modelName: "addTime", label: "Add Time", width: "100", sortable: true },
        { key: "actions", modelName: "actions", label: "Actions", width: "250" },
      ],
    }
  },
  methods: {
    formatWithCurrentTimezone,
    isAccrualEnabled,
    getErpCanWriteGlobal,
    getAccrualIconStyle,
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    async loadAll() {
      await this.loadAccruals();
      await this.loadCurrencyProfiles();
      await this.loadCurrencies();
      await this.loadChargeCodes();
      await this.loadWarehouseCodes();
    },
    async loadAccruals() {
      this.$nextTick(() => {
        this.$refs.advanceTable.loadList();
      });
    },
    loadCurrencyProfiles() {
      axios.get(apis.erpCurrencyProfiles)
        .then(response => {
          this.currencyProfiles = response.data.data.data;
        })
    },
    loadCurrencies() {
      axios.get(apis.erpCurrencyProfileItems, {params: {currencyProfileId: this.currentAccrual ? this.currentAccrual.currencyProfileId : this.profile.currencyProfileId}})
        .then(response => {
          let currencies = [];
          response.data.data.data.forEach(item => {
            currencies.push(item.baseCurrency);
          });
          this.currencies = _.uniq(currencies).sort();
        })
    },
    async loadChargeCodes() {
      const response = await axios.get(apis.erpChargeCodes);
      this.chargeCodes = response.data.data.data;
    },
    async loadWarehouseCodes() {
      axios.get(
        apis.erpGetMasterData + "/WAREHOUSE_CODE/LIST",
      ).then(response => {
        this.warehouseCodes = response.data.data.value;
      });
    },
    createAccrual(accountCode) {
      this.action = 'ADD';
      this.isShowForm = true;

      this.currentAccrual = {
        accountCode: accountCode,
        billingPeriod: null,
        billingPeriodMonth: null,
        customerCategory: null,
        destinationCountry: null,
        warehouseCode: null,
        chargeCode: null,
        originalCurrencyReceivable: null,
        originalCurrencyPayable: null,
        currencyProfileId: this.profile.currencyProfileId,
        originalTotalAmount: 0,
        totalAmount: 0,
        currency: 'HKD',
        exchangeRate: null,
        remark: null,
        charges: []
      };

      this.arKeys.forEach((code) => {
        this.currentAccrual.charges.push({code: 'AR-' + code, amount: 0});
        this.currentAccrual.charges.push({code: 'AP-' + code, amount: 0});
      })
    },

    recalculateTotal() {
      this.currentAccrual.originalTotalAmountReceivable = 0;
      this.currentAccrual.originalTotalAmountPayable = 0;
      this.currentAccrual.charges.forEach(charge => {
        charge.amount = parseFloat(charge.amount).toFixed(2);

        if (charge.code.startsWith('AR-')) {
          this.currentAccrual.originalTotalAmountReceivable += parseFloat(charge.amount);
        } else {
          this.currentAccrual.originalTotalAmountPayable += parseFloat(charge.amount);
        }
      });
      this.currentAccrual.originalTotalAmountReceivable = this.currentAccrual.originalTotalAmountReceivable.toFixed(2);
      this.currentAccrual.originalTotalAmountPayable = this.currentAccrual.originalTotalAmountPayable.toFixed(2);
      this.currentAccrual.totalAmountReceivable = (this.currentAccrual.originalTotalAmountReceivable * this.currentAccrual.exchangeRateReceivable).toFixed(2);
      this.currentAccrual.totalAmountPayable = (this.currentAccrual.originalTotalAmountPayable * this.currentAccrual.exchangeRatePayable).toFixed(2);
      this.isFormValid = true;
    },

    loadExchangeRateReceivable() {
      if (this.currentAccrual.originalCurrencyReceivable === this.currentAccrual.currency) {
        this.currentAccrual.exchangeRateReceivable = 1;
        this.isFormValid = true;
        this.recalculateTotal();
        return;
      }

      this.currentAccrual.exchangeRate = NaN;
      axios.get(apis.erpCurrencyProfileItems, {params: {currencyProfileId: this.currentAccrual ? this.currentAccrual.currencyProfileId : this.profile.currencyProfileId}})
        .then((response) => {
          response.data.data.data.forEach(rate => {
            if (rate.baseCurrency === this.currentAccrual.originalCurrencyReceivable && rate.quoteCurrency === this.currentAccrual.currency) {
              this.currentAccrual.exchangeRateReceivable = rate.exchangeRate;
            }
          })
        }).finally(() => {
          if (isNaN(this.currentAccrual.exchangeRateReceivable) || this.currentAccrual.exchangeRateReceivable <= 0) {
            this.currentAccrual.exchangeRate = '';
            this.promptError(this.currentAccrual.originalCurrencyReceivable + '/' + this.currentAccrual.currency + ' pair not found in Currency Profile. Please enter Exchange Rate manually.')
            this.isFormValid = false;
          } else {
            this.isFormValid = true;
            this.recalculateTotal();
          }
        });
    },

    loadExchangeRatePayable() {
      if (this.currentAccrual.originalCurrencyPayable === this.currentAccrual.currency) {
        this.currentAccrual.exchangeRatePayable = 1;
        this.isFormValid = true;
        this.recalculateTotal();
        return;
      }

      this.currentAccrual.exchangeRate = NaN;
      axios.get(apis.erpCurrencyProfileItems, {params: {currencyProfileId: this.currentAccrual ? this.currentAccrual.currencyProfileId : this.profile.currencyProfileId}})
        .then((response) => {
          response.data.data.data.forEach(rate => {
            if (rate.baseCurrency === this.currentAccrual.originalCurrencyPayable && rate.quoteCurrency === this.currentAccrual.currency) {
              this.currentAccrual.exchangeRatePayable = rate.exchangeRate;
            }
          })
        }).finally(() => {
        if (isNaN(this.currentAccrual.exchangeRatePayable) || this.currentAccrual.exchangeRatePayable <= 0) {
          this.currentAccrual.exchangeRate = '';
          this.promptError(this.currentAccrual.originalCurrencyPayable + '/' + this.currentAccrual.currency + ' pair not found in Currency Profile. Please enter Exchange Rate manually.')
          this.isFormValid = false;
        } else {
          this.isFormValid = true;
          this.recalculateTotal();
        }
      });
    },

    openModalForUpload() {
      this.$refs.uploadView.showUpload('Upload Accrual', 'ACCRUAL', {maxSize: 10485760, allowedExtension: 'xlsx'}, (form) => {
        this.isLoading = true;

        axios.post(
          apis.erpProcessAccruals,
          form
        ).then((response) => {
          this.loadAccruals();
          this.$refs.uploadView.hideUpload();
          this.$refs.excelResult.showResult(response.data.data);
        }).catch(e => {
          this.promptError(e);
        }).finally(() => {
          this.isLoading = false;
        });
      });
    },

    submitCreateAccrual() {
      this.$refs.mainForm.validate()
        .then(() => {
          axios.post(apis.erpAccruals, this.currentAccrual)
            .then((result) => {
              this.isShowForm = false;
              this.loadAccruals();
              EventBus.$emit("accrual-updated");
            }).catch((e) => {
              this.promptError(e);
            });
        })
        .catch((errors) => {
          let errorMessages = [];
          for (const i in errors) {
            for (const j in errors[i]) {
              errorMessages.push(errors[i][j]['message']);
            }
          }

          this.promptError(errorMessages.join(', '));
        });
    },

    submitEditAccrual() {
      this.$refs.mainForm.validate()
        .then(() => {
          axios.put(apis.erpAccruals + '/' + this.currentAccrual.id, this.currentAccrual)
            .then((result) => {
              this.isShowForm = false;
              this.loadAccruals();
              EventBus.$emit("accrual-updated");

              this.promptInfo('Accrual updated')
            }).catch((e) => {
            this.promptError(e);
          });
        })
        .catch((errors) => {
          let errorMessages = [];
          for (const i in errors) {
            for (const j in errors[i]) {
              errorMessages.push(errors[i][j]['message']);
            }
          }

          this.promptError(errorMessages.join(', '));
        });
    },

    editAccrualById(id) {
      axios.get(apis.erpAccruals + '/' + id)
        .then((result) => {
          this.editAccrual(result.data.data);
        }).catch((e) => {
          this.promptError(e);
        });
    },

    editAccrual(item) {
      if (item.status === 'DELETED') return;

      item.billingPeriodMonth = item.billingPeriodStart;
      this.currentAccrual = _.clone(item);

      this.action = 'EDIT';
      this.isShowForm = true;

      this.recalculateTotal();
    },

    confirmDeleteAccrual(accrual) {
      this.currentAccrual = accrual;

      this.loadAccrualInvoice().then(() => {
        this.isShowInvoices = true;
        this.action = 'DELETE';
      });
    },

    doubleConfirmDeleteAccrual(accrual, extraWarning) {
      this.$confirm(
        (extraWarning ? '<p style="color: red">All unconfirmed invoices will be unlinked automatically.</p>' : '') + 'Confirm delete Accrual?',
        'Accrual: ' + accrual.accrualNumber,
        {dangerouslyUseHTMLString: true}
      )
        .then(() => {
          return axios.delete(apis.erpAccruals + '/' + accrual.id)
        })
        .then(response => {
          this.isShowInvoices = false;
          this.promptInfo('Accrual Confirmed');
          EventBus.$emit("accrual-updated")
        })
        .catch(e => {
          if (e === 'cancel') return;
          this.promptError(e);
        });
    },

    editSunCurrencyProfile() {
      axios.get(
        apis.erpCurrencyProfiles
      ).then(response => {
        this.currencyProfiles = response.data.data.data;

        return axios.get(
          apis.erpGetMasterData + "/CURRENCY_PROFILE/SUN"
        )
      }).then(response => {
        this.isShowCurrencyProfile = true;
        this.currencyProfileId = response.data.data.value;
      });
    },

    submitSunCurrencyProfile() {
      axios.put(
        apis.erpGetMasterData + "/CURRENCY_PROFILE/SUN",
        {value: this.currencyProfileId})
        .then(response => {
          this.promptInfo('SUN Currency Profile updated.')
          this.isShowCurrencyProfile = false;
        });
    },

    loadAccrualInvoice() {
      return axios.get(apis.erpAccruals + '/' + this.currentAccrual.id + '/invoices')
        .then((response) => {
          this.accrualInvoices = response.data.data.byInvoice;
          this.accrualInvoices2 = response.data.data.byInvoiceItem;
        }).catch((e) => {
          this.promptError(e)
        });
    },

    loadAccrualSummary() {
      return axios.get(apis.erpAccruals + '/' + this.currentAccrual.id + '/summaries')
        .then((response) => {
          console.log(response.data.data);
          this.accrualSummaries = response.data.data;
        }).catch((e) => {
          this.promptError(e)
        });
    },

    showInvoices(item) {
      if (item.status === 'DELETED') return;

      this.currentAccrual = item;

      this.loadAccrualInvoice().then(() => {
        this.isShowInvoices = true;
        this.action = 'SHOW';
      });

      axios.get(apis.erpInvoices + '?accountCode=' + this.currentAccrual.accountCode)
        .then((response) => {
          this.companyInvoices = response.data.data.data;
        }).catch((e) => {
          this.promptError(e)
        });
    },

    showSummaries(item) {
      if (item.status === 'DELETED') return;

      this.currentAccrual = item;

      this.loadAccrualSummary().then(() => {
        this.isShowSummaries = true;
        this.action = 'SHOW';
      });
    },

    addInvoiceSubmit() {
      axios.post(apis.erpAccruals + '/' + this.currentAccrual.id + '/invoices',{invoiceNumber: this.newInvoiceNumber})
        .then((response) => {
          this.loadAccrualInvoice();
        }).catch((e) => {
          this.promptError(e)
        });
    },

    editRemark(item) {
      this.currentAccrual = item;
      this.isShowRemark = true;
    },

    submitRemark() {
      axios.patch(apis.erpAccruals + '/' + this.currentAccrual.id, {
        remark: this.currentAccrual.remark,
        faRemark: this.currentAccrual.faRemark,
      })
        .then(() => {
          this.loadAccruals();
          EventBus.$emit("accrual-updated");
          this.isShowRemark = false;
          this.promptInfo('Remark updated');
        }).catch((e) => {
          this.promptError(e)
        });
    },

    confirmAccrual(accrual) {
      this.currentAccrual = accrual;

      this.loadAccrualInvoice().then(() => {
        this.isShowInvoices = true;
        this.action = 'CONFIRM';
      });
    },

    doubleConfirmConfirmAccrual(accrual, extraWarning) {
      this.$confirm(
        (extraWarning ? '<p style="color: red">All unconfirmed invoices will be unlinked automatically.</p>' : '') + 'Confirm Accrual?',
        'Accrual: ' + accrual.accrualNumber,
        {dangerouslyUseHTMLString: true}
      )
        .then(() => {
          return axios.post(apis.erpAccruals + '/' + accrual.id + '/confirm', this.newItem)
        })
        .then(response => {
          this.isShowInvoices = false;
          this.promptInfo('Accrual Confirmed');
          EventBus.$emit("accrual-updated")
        })
        .catch(e => {
          if (e === 'cancel') return;
          this.promptError(e);
        });
    },

    confirmFaAccrual(accrual) {
      this.$confirm('F&A Confirm Accrual?', 'Invoice: ' + accrual.accrualNumber)
        .then(() => {
          return axios.post(apis.erpAccruals + '/' + accrual.id + '/confirmFa', this.newItem)
        })
        .then(response => {
          this.promptInfo('Accrual F&A Confirmed');
          EventBus.$emit("accrual-updated")
        })
        .catch(e => {
          if (e === 'cancel') return;
          this.promptError(e);
        });
    },

    onBillingPeriodMonthChange() {
      this.currentAccrual.billingPeriod = this.currentAccrual.billingPeriodMonth.substring(0, 7).replace("-", "/0");
      this.currentAccrual.billingPeriodStart = moment(this.currentAccrual.billingPeriodMonth).startOf('month').format('YYYY-MM-DD');
      this.currentAccrual.billingPeriodEnd = moment(this.currentAccrual.billingPeriodMonth).endOf('month').format('YYYY-MM-DD');
    },

    viewInvoice(invoice) {
      this.$router.push({
        name: 'erp-invoice-view',
        params: { id: invoice.id, company_id: invoice.organizationProfileId }
      })
    },

    editInvoice(invoice) {
      this.$router.push({
        name: 'erp-invoice-edit',
        params: { id: invoice.id, company_id: invoice.organizationProfileId }
      })
    },

    removeInvoice(accrual, invoice) {
      this.$confirm('Confirm remove invoice from accrual?', 'Invoice: ' + invoice.invoiceNumber)
        .then(() => {
          return axios.delete(apis.erpAccruals + '/' + accrual.id + '/invoices/' + invoice.id)
        })
        .then(response => {
          this.promptInfo('Invoice removed');
          this.loadAccrualInvoice();
        })
        .catch(e => {
          this.promptError(e);
        });
    },

    exportExcel(exportType) {
      this.isLoading = true;

      let urlParams = '';
      let params = this.$refs.advanceTable.filter
      params.pageSize = MAX_PAGE_SIZE;

      for (const k in params){
        if (typeof params[k] != 'undefined') urlParams += k + "=" + params[k] + "&"
      }

      axios.get(apis.erpAccruals.substring(1) + "/export?exportType=" + exportType + '&' + urlParams, {
        responseType: 'blob'
      })
      .then((response) => {
        const anchorElement = document.createElement('a');
        document.body.appendChild(anchorElement);
        anchorElement.style.display = 'none';

        const url = window.URL.createObjectURL(response.data);

        let filename;
        if (exportType === 'SUN_TEXT') {
          params.isShowDeleted = false;
          filename = 'Accrual-Export-Sun.txt';
        } else if (exportType === 'SUN_EXCEL') {
          params.isShowDeleted = false;
          filename = 'Accrual-Export-Sun.xlsx';
        } else if (exportType === 'VARIANCE_EXCEL') {
          filename = 'Accrual-Export-Variance.xlsx';
        } else if (exportType === 'INVOICE_MAPPING_EXCEL') {
          filename = 'Accrual-Export-Invoice-Mapping.xlsx';
        } else if (exportType === 'SUMMARY_MAPPING_EXCEL') {
          filename = 'Accrual-Export-Summary-Mapping.xlsx';
        }

        anchorElement.href = url;
        anchorElement.download = filename;
        anchorElement.click();

        window.URL.revokeObjectURL(url);
      }).catch((e) => {
        this.promptError(e);
      }).finally(() => {
        this.isLoading = false;
      });
    },

    showAudits(invoice) {
      this.$refs.auditTrail.show({objectId: invoice.id, className: 'com.kerrylogistics.dashboard.entities.erp.Accrual', name: 'Accrual'});
    }
  }
}
</script>

